import React, {useEffect, useState} from "react";
import QrReader from "react-qr-reader";
import {useHistory} from "react-router-dom";

const SectionValidar: React.FC = () => {
    const [showQrCodeScanner, setShowQrCodeScanner] = useState(false);
    const [input, setInput] = useState('')
    let history = useHistory();

    useEffect(() => {
        if (showQrCodeScanner) {
            // @ts-ignore
            const $ = window.jQuery;
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#qr-scanner").offset().top
            }, 1000);
        }
    }, [showQrCodeScanner])

    const handleScan = (code: string | null) => {
        if (code) {
            code = code.replace('https://epolo.co/g/', '');
            history.replace(`./certificado/${code}`);
        }
    };
    const handleClick = () => {
        history.replace(`./certificado/${input}`);
    };



    const handleError = (err: any) => {
        console.error('ERROR SCANNER:', err);
        alert('Não conseguimos utilizar sua camera para scanear, favor digitar o código manualmente.');
    };

    return (
        <section id="validar" className="wow fadeInUp clearfix">
            <div className="container">
                <div className="row d-flex justify-content-between align-items-start">
                    <div className="col-12 col-md-7">
                        <div className="intro-info">
                            <h2>Garantia Elo Laboratórios</h2>
                            <p>
                                As lentes Polo contam com os processos mais tecnológicos <br/>
                                e de alta precisão do mercado óptico!<br/>
                                A Elo Laboratórios oferece qualidade e garantia para as suas lentes!
                            </p>
                            <h3 className="mt-md-5">Validar minha lente</h3>
                            <p>
                                Para verificar a autenticidade da sua lente, informe o código no campo abaixo<br/>
                                e clique em consultar, ou use o scanner para ler o código QR Code.
                            </p>
                            <div className="form mt-md-4">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Informe o código"
                                               name="code"
                                               value={input} onChange={e => setInput(e.currentTarget.value)}
                                               id="iptcode"
                                               aria-label="código" aria-describedby="button-consultar"/>
                                        <div className="col-12 col-sm px-0 mt-3 mt-sm-0 input-group-append">
                                            <button  onClick={handleClick} className="btn btn-success" type="submit"
                                                    id="button-consultar">Consultar
                                            </button>
                                            <button onClick={() => setShowQrCodeScanner(!showQrCodeScanner)}
                                                    className="btn btn-primary"
                                                    type="button"
                                                    id="button-scanner">
                                                <img src="/img/icon-qrcode.png" alt="Elo Laboratórios"/>
                                                scanner
                                            </button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md my-5 my-md-0">
                        {!showQrCodeScanner && <div id="banner" className="banner">
                            <img src="/img/bg-elolab.jpg"
                                 alt="Elo Laboratórios - Lentes com qualidade e garantia"
                                 className="img-fluid"/>
                        </div>}

                        {showQrCodeScanner &&
                        <div id="qr-scanner" className="flex-column d-flex justify-content-start align-items-center">
                            <h4>Alinhe seu código dentro da linha vermelha*</h4>

                            <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={{width: '400px'}}
                            />
                            <br/>
                            <small>* Em IPHONES o scanner funcionará apenas no navegador SAFARI, em smartphones com sistema ANDROID o scanner funcionará apenas no navegador CHROME.</small>
                        </div>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionValidar;
