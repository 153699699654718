import React from "react";

const Footer: React.FC = () => {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <img src="/img/elo_lab.png" alt="Elo Laboratórios" className="d-block mx-auto"
                                 height="40"/>
                            <div className="copyright mt-3">
                                &copy; 2020 <strong>Elo Laboratórios</strong>. Todos os direitos reservados
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
