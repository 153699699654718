import React from "react";

const SectionContato: React.FC = () => {
    return (
        <section id="contato">
            <div className="container-fluid">

                <div className="section-header">
                    <h3>Contato</h3>
                </div>

                <div className="row justify-content-center wow fadeInUp">

                    <div className="col-10">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-3 info">
                                <img src="/img/phone.svg" alt="Elo Laboratórios" height="35" className="float-left pt-1 pr-2"/>
                                <p>(45) 0000 0000</p>
                            </div>
                            <div className="col-12 col-md-4 info">
                                <i className="ti-email pr-2"/>
                                <p>
                                    <a href="mailto:someone@example.com" target="_top">
                                        contato@elolaboratorios.com.br
                                    </a>
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    );
};

export default SectionContato;
