import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link, useParams, useHistory} from "react-router-dom";
import {IResultCertificado} from "./types";

const axios = require('axios').default;

const ValidacaoCertificado: React.FC<any> = (props) => {
    const [certificado, setCertificado] = useState<IResultCertificado | null>(null);
    let {id: certificadoId} = useParams();
    let history = useHistory();

    const consultingProduction = (certificadoId: string) => {
        let host = 'https://services.elolaboratorios.com.br';

        switch(window.location.host){

            case 'garantia.elolaboratorios.local':
            case '192.168.1.100:5000':
            case '192.168.1.100:3000':
                host = 'http://services.elolaboratorios.local';
                break;
                
            case 'garantia.elolaboratorios.com.br':
            default:
                host = 'https://services.elolaboratorios.com.br';

        }

        return axios.get(  `${host}/api/v1/garantia?code=${certificadoId}`)
            .then((response: any) => response.data.data)
            .catch((error: any) => {
                // handle error
                console.log('ERROR PRODUCTION:', error);
            });
    };

    const consulting = async (certificadoId: string) => {

        let result;
        result = await consultingProduction(certificadoId);


        if (result) {
            setCertificado(result);
        } else {
            history.replace('/error');
        }
    };

    useEffect(() => {
        if (certificadoId) {
            consulting(certificadoId)
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Header/>

            {!certificado &&
            <section id="validar" className="wow fadeInUp clearfix">
                <div className="container">
                    <h3>Aguarde!</h3>
                </div>
            </section>
            }

            {certificado &&
            <section id="validar" className="wow fadeInUp clearfix">
                <div className="container">

                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-12 col-md">

                            <div className="border-frame">

                                <div className="card-cod">
                                    <div className="content">

                                        <img src="/img/elo_lab.png" alt="Elo Laboratórios" className="logo"/>

                                        <h2 className="title">
                                            certificado de garantia
                                        </h2>


                                        <div className="codigo-valido">

                                            <div className="area-texto">
                                                <h4 className="text-center">Parabéns! <br/>
                                                    <span>Seu produto é Original Elo Laboratórios</span>
                                                </h4>

                                                <div className="text-center">

                                                    <div className="row infos">
                                                        <div className="col-12 col-md mb-3 mb-md-0">
                                                            <p>Cliente</p>
                                                            <p><b>{certificado?.cliente}</b></p>
                                                        </div>

                                                        <div className="col-12 col-md mb-3 mb-md-0">
                                                            <p>Grau</p>
                                                            <table className="table table-responsive-sm">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">{' '}</th>
                                                                    <th scope="col">Esf</th>
                                                                    <th scope="col">Cil</th>
                                                                    <th scope="col">Eixo</th>
                                                                    <th scope="col">Adição</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <th scope="row">OD</th>
                                                                    <td>
                                                                        { (certificado.espec_od && certificado.espec_od.esferico) && certificado.espec_od.esferico.toFixed(2)}
                                                                    </td>
                                                                    <td>{ (certificado.espec_od && certificado.espec_od.cil) && certificado.espec_od.cil.toFixed(2)}</td>
                                                                    <td>{certificado.espec_od.eixo}</td>
                                                                    <td>{ (certificado.espec_od && certificado.espec_od.adicao) &&  certificado.espec_od.adicao.toFixed(2)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">OE</th>
                                                                    <td>{ (certificado.espec_oe && certificado.espec_oe.esferico) && certificado.espec_oe.esferico.toFixed(2)}</td>
                                                                    <td>{ (certificado.espec_oe && certificado.espec_oe.cil) && certificado.espec_oe.cil.toFixed(2)}</td>
                                                                    <td>{certificado.espec_oe.eixo}</td>
                                                                    <td>{(certificado.espec_oe && certificado.espec_oe.adicao) && certificado.espec_oe.adicao.toFixed(2)}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="col-12 col-md mb-3 mb-md-0">
                                                            <p>Projeto</p>
                                                            <p><b>{certificado.projeto.nome}</b></p>
                                                        </div>

                                                        <div className="col-12 col-md mb-3 mb-md-0">
                                                            <p>Material</p>
                                                            <p><b>{certificado.material.nome}</b></p>
                                                        </div>

                                                        <div className="col-12 col-md">
                                                            <p>Tratamento</p>
                                                            <p><b>{certificado.tratamento.nome}</b></p>
                                                        </div>
                                                    </div>

                                                    <p className="mb-3">Foi registrado em:</p>
                                                    <div className="col-12 col-md-11 col-lg-10 mx-auto">
                                                        <p className="otica">
                                                            {certificado.otica.nome}, {certificado.otica.cidade} |
                                                            {certificado.otica.telefone}
                                                        </p>
                                                        <hr className="mt-1 mb-4"/>
                                                    </div>
                                                    <p>
                                                        na data de <span
                                                        className="data">{certificado.created_at_format}</span>
                                                    </p>
                                                    <p className="cod">Certificado Digital: {certificado.uuid}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Link to="/#validar" className="btn btn-scanner">validar novo código</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            }

            <Footer/>
        </>
    );
};

export default ValidacaoCertificado;
